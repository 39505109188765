import axios from 'axios';

const clienteAxios = axios.create({
  baseURL: 'https://api.controlethos.com/api/v1/',
  // baseURL: 'https://localhost:7184/api/v1/',
  responseType: 'json',
});

const token = 'iJIUzI1';
clienteAxios.interceptors.request.use(
  (config) => {
    const configCopia = { ...config };
    configCopia.headers.authorization = `Bearer ${token}`;
    return configCopia;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default clienteAxios;
