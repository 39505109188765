import clienteAxios from '../config/axios';

const ObtenerConsulta = async (controller, metodo, modelo) =>
  clienteAxios.post(`${controller}/${metodo}`, modelo);

const ObtenerConsultaGeneral = async (controller, metodo) =>
  clienteAxios.post(`${controller}/${metodo}`);

  const ObtenerConsulta2 = async (controller, metodo, modelo) =>
  clienteAxios.post(`${controller}/${metodo}`, modelo,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export { ObtenerConsulta, ObtenerConsultaGeneral, ObtenerConsulta2 };
